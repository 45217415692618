import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import momentPhoenix from "../../../../../helpers/momentPhoenix";

const DailyStockoutsTab = ({ jobId }) => {
  const [stockouts, setStockouts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useContext(AuthContext);

  const { openSnackMessage } = useContext(SnackAlertContext);
  let navigate = useNavigate();
  const theme = useTheme();

  const getStockoutsByJob = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(
        `/jobs/${jobId}/stockouts?status=all`,
        data
      );
      console.log("STOCK OUTS", response.data);
      setStockouts(response.data.stockouts);
    } catch (error) {
      if (!error.code === "ERR_CANCELED") {
        // If it's not an abort controller message
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getStockoutsByJob();
  }, []);

  return (
    <Box>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            height: 50,
            marginTop: 16,
          }}
          variant="outlined"
          onClick={() => {
            navigate(`/jobs/${jobId}/daily-stockouts/new`);
          }}
        >
          + New
        </Button>
      </div>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Job</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Confirmed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stockouts &&
              stockouts.map((grab, index) => (
                <TableRow
                  onClick={() => navigate(`/daily-stockouts/${grab.id}`)}
                  key={index}
                  sx={{
                    "& td": { border: 0 },
                    cursor: "pointer",
                    "&:hover": {
                      background: "#5A5A5A",
                    },
                  }}
                >
                  <TableCell>
                    {momentPhoenix(grab.created_on).format(
                      "MM/DD/YY hh:mm A z"
                    )}
                  </TableCell>
                  <TableCell>{grab.transactionid}</TableCell>
                  <TableCell>{grab.user_full_name}</TableCell>
                  <TableCell>
                    {grab.job_name
                      ? `${grab.projectid} - ${grab.job_name}`
                      : "[UNLISTED]"}
                  </TableCell>
                  <TableCell>{grab.status_description}</TableCell>
                  <TableCell>
                    {grab.approved_on
                      ? "Posted"
                      : grab.rejected_on
                      ? "Rejected"
                      : "Pending"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DailyStockoutsTab;
