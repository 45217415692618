import { useContext, useEffect, useState } from "react";
import InventoryTracking from "../../../../../api/InventoryTracking";
import ListItem from "../../../../../component/ListItem";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DeliverFromStaging from "./DeliverFromStaging";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";

const StagedTab = ({ jobId }) => {
  const [stagedTransactions, setStagedTransactions] = useState([]);

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [selectVehicleModalOpen, setSelectVehicleModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = useContext(AuthContext);

  const { openSnackMessage } = useContext(SnackAlertContext);
  let navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getCurrentStaged = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs/${jobId}/staged`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setStagedTransactions(response.data.staged);
    } catch (error) {
      console.log(error);
    }
  };

  const deliverItems = async () => {
    try {
      setIsLoading(true);
      let items = [];

      // Add Selected Items in the request body
      let transactionIndex;
      let itemIndex;
      for (
        transactionIndex = 0;
        transactionIndex < stagedTransactions.length;
        transactionIndex++
      ) {
        let transaction = stagedTransactions[transactionIndex];

        transaction.checked = false;
        for (itemIndex = 0; itemIndex < transaction.items.length; itemIndex++) {
          let item = transaction.items[itemIndex];

          if (item.quantity > 0) {
            items.push(item);
          }
        }
      }

      let data = { stagedItems: items };

      const response = await InventoryTracking.post(
        `/jobs/${jobId}/delivered`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      console.log("DELIVERED!", data);
      openSnackMessage("success", "Items successfull added to delivered");

      window.location = `/jobs/${jobId}/delivered`;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadItemsOnTruck = async (vehicleId) => {
    try {
      setIsLoading(true);
      let items = [];

      // Add Selected Items in the request body
      let transactionIndex;
      let itemIndex;
      for (
        transactionIndex = 0;
        transactionIndex < stagedTransactions.length;
        transactionIndex++
      ) {
        let transaction = stagedTransactions[transactionIndex];

        transaction.checked = false;
        for (itemIndex = 0; itemIndex < transaction.items.length; itemIndex++) {
          let item = transaction.items[itemIndex];

          if (item.quantity > 0) {
            items.push(item);
          }
        }
      }

      let data = { stagedItems: items };

      const response = await InventoryTracking.post(
        `/jobs/${jobId}/loaded-on-truck/${vehicleId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      console.log("LOADED ON TRUCK!", data);
      openSnackMessage("success", "Items loaded on truck");

      window.location = `/jobs/${jobId}/loaded`;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentStaged();
  }, []);

  // useEffect(() => {
  //   const controller = new AbortController();

  //   getStagedActivities(controller);
  //   return () => {
  //     controller.abort(); // abort api call on unmount for cleanup
  //   };
  // }, [startDate, endDate]);

  return (
    <div>
      <DeliverFromStaging
        isLoading={isLoading}
        stagedTransactions={stagedTransactions}
        setStagedTransactions={setStagedTransactions}
        deliverItems={deliverItems}
        loadItemsOnTruck={loadItemsOnTruck}
        previewModalOpen={previewModalOpen}
        setPreviewModalOpen={setPreviewModalOpen}
        selectVehicleModalOpen={selectVehicleModalOpen}
        setSelectVehicleModalOpen={setSelectVehicleModalOpen}
        onTransactionClick={(transactionId) => {
          navigate(`/jobs/${jobId}/staged/${transactionId}`);
        }}
      />
    </div>
  );
};

export default StagedTab;
