import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import PageContent from "../../../../component/PageContent";
import InventoryTracking from "../../../../api/InventoryTracking";
import { useTheme } from "@emotion/react";
import momentPhoenix from "../../../../helpers/momentPhoenix";

const InventoryCounts = () => {
  const navigate = useNavigate();
  const { authToken, isAdmin } = useContext(AuthContext);
  const [inventoryCounts, setInventoryCounts] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getInventoryCounts = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(`/inventory/counts`, data);
      setInventoryCounts(response.data.inventoryCounts);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getInventoryCounts(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return (
    <PageContent>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Inventory Counts
      </h2>
      <div
        style={{
          display: "flex",
          position: "relative",
          flexDirection: "row",
          marginTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          justifyContent: isSmallScreen ? "center" : "flex-end",
        }}
      >
        <Button
          variant="outlined"
          style={{
            width: isSmallScreen ? "100%" : 200,
          }}
          onClick={() => {
            navigate("/inventory/counts/new");
          }}
        >
          + New
        </Button>
      </div>

      <TableContainer component={Box}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell>Submitted On</TableCell>
              <TableCell>Submitted By</TableCell>
              <TableCell>Approved On</TableCell>
              <TableCell>Approved By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryCounts &&
              inventoryCounts.map((count, index) => (
                <TableRow
                  onClick={() => navigate(`/inventory/counts/${count.id}`)}
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>COUNT-{count.id}</TableCell>

                  <TableCell component="th" scope="row">
                    {momentPhoenix(count.created_on).format(
                      "MM/DD/YY hh:mm A z"
                    )}
                  </TableCell>
                  <TableCell>{count.created_by_full_name}</TableCell>

                  <TableCell component="th" scope="row">
                    {count.submitted_on
                      ? momentPhoenix(count.submitted_on).format(
                          "MM/DD/YY hh:mm A z"
                        )
                      : ""}
                  </TableCell>
                  <TableCell>{count.submitted_by_full_name}</TableCell>

                  <TableCell component="th" scope="row">
                    {count.approved_on
                      ? momentPhoenix(count.approved_on).format(
                          "MM/DD/YY hh:mm A z"
                        )
                      : ""}
                  </TableCell>
                  <TableCell>{count.approved_by_full_name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContent>
  );
};

export default InventoryCounts;
