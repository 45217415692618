import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { GrabsContext } from "../../../context/GrabsContext";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";
import useDailyStockouts from "../../../customHooks/useDailyStockouts";
import TextFieldDebounced from "../../../component/TextFieldDebounced";
import InfiniteScrollerTable from "../../../component/InfiniteScroller/InfiniteScrollerTable";
import ListFooter from "../../../component/InfiniteScroller/ListFooter";
import momentPhoenix from "../../../helpers/momentPhoenix";

const DailyStockouts = () => {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const { clearGrabs } = useContext(GrabsContext);
  const {
    searchQuery,
    setSearchQuery,
    status,
    setStatus,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  } = useDailyStockouts(authToken, 50, "all");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PageContent>
      <div
        style={{
          position: "relative",
          flexDirection: "row",
          display: useMediaQuery(theme.breakpoints.down("sm"))
            ? "column"
            : "row",
          marginTop: 16,
        }}
      >
        <h2
          style={{
            textAlign: "center",
          }}
        >
          Daily Stockouts
        </h2>
      </div>

      <div
        style={{
          display: "flex",
          position: "relative",
          flexDirection: isSmallScreen ? "column" : "row",
          marginTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          justifyContent: isSmallScreen ? "center" : "flex-end",
        }}
      >
        <TextField
          size="small"
          fullWidth={isSmallScreen}
          sx={{
            width: isSmallScreen ? "100%" : 200,
            marginRight: isSmallScreen ? 0 : "8px",
            marginTop: isSmallScreen ? "8px" : 0,
          }}
          select
          variant="outlined"
          label="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"pending"}>Pending</MenuItem>
        </TextField>
        <TextFieldDebounced
          placeholder="search..."
          value={searchQuery}
          size="small"
          sx={{
            width: isSmallScreen ? "100%" : 200,
            marginRight: isSmallScreen ? 0 : "8px",
            marginTop: isSmallScreen ? "8px" : 0,
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          variant="outlined"
          style={{
            width: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : 200,
          }}
          onClick={() => {
            clearGrabs();
            navigate("/daily-stockouts/new");
          }}
        >
          + New
        </Button>
      </div>

      <InfiniteScrollerTable
        TableContainerComponent={Paper}
        TableStyle={{ minWidth: "650px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>

              <TableCell
                sx={{
                  minWidth: "150px",
                  maxWidth: "200px",
                  textOverflow: "ellipsis",
                }}
              >
                Job
              </TableCell>
              <TableCell>Pulled By</TableCell>
              <TableCell>ID</TableCell>
              {/* <TableCell>Status</TableCell>
              <TableCell>Posted</TableCell> */}
            </TableRow>
          </TableHead>
        )}
        items={results}
        renderRow={(item, index) => {
          return (
            <TableRow
              onClick={() => navigate(`/pull-requests/${item.id}`)}
              key={index}
              sx={{
                // "& td": { border: 0 },
                cursor: "pointer",
                "&:hover": {
                  background:
                    theme.palette.mode == "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.secondary.light,
                },
              }}
            >
              <TableCell>
                {momentPhoenix(item.created_on).format("MM/DD/YY hh:mm A")}
              </TableCell>

              <TableCell
                sx={{
                  minWidth: "150px",
                  maxWidth: "200px",
                  textOverflow: "ellipsis",
                }}
              >
                {item.job_name
                  ? `${item.projectid} - ${item.job_name}`
                  : "[UNLISTED]"}
              </TableCell>
              <TableCell>{item.user_full_name}</TableCell>
              <TableCell>{item.transactionid}</TableCell>
              {/* <TableCell>{item.status_description}</TableCell>
              <TableCell>
                {item.approved_on
                  ? "Yes"
                  : item.rejected_on
                  ? "Rejected"
                  : "No"}
              </TableCell> */}
            </TableRow>
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </PageContent>
  );
};

export default DailyStockouts;
