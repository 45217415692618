import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Card, Link, Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import PageContent from "../../../component/PageContent";
import InventoryTracking from "../../../api/InventoryTracking";
import ReactToPrint from "react-to-print";
import { useTheme } from "@emotion/react";
import momentPhoenix from "../../../helpers/momentPhoenix";

// To print multiple pages, wrap functional component with forwardRef
//"To print a functional component ensure it is wrapped with `React.forwardRef`, and ensure the forwarded ref is used. See the README for an example: https://github.com/gregnb/react-to-print#examples"
const PrintPullDetails = forwardRef((props, ref) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authToken, userBranch, role, isAdmin } = useContext(AuthContext);
  const [details, setDetails] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const componentRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  const getGrabbedDetails = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(`/pulls/${id}`, data);
      console.log(response.data);
      setDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getGrabbedDetails(controller);
    return () => {
      controller.abort();
    };
  }, [id]);

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const isPullRequest = !!details?.is_stage_request;
  const isApprovedOrRejected = !!details?.approved_by || !!details?.rejected_by;

  return (
    <PageContent>
      {details ? (
        <div>
          <div
            style={{
              marginTop: "20px",
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ReactToPrint
              trigger={() => {
                return <Button variant="contained">Print Details</Button>;
              }}
              content={() => componentRef.current}
              onBeforeGetContent={() => {
                return new Promise((resolve) => {
                  promiseResolveRef.current = resolve;
                  setIsPrinting(true);
                });
              }}
              onAfterPrint={() => {
                // Reset the Promise resolve so we can print again
                promiseResolveRef.current = null;
                setIsPrinting(false);
              }}
            />
          </div>
          <div
            ref={componentRef}
            style={{
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div>
                <h2
                  style={{
                    textAlign: "center",
                    marginBottom: "0",
                  }}
                >
                  {details.is_stage_request ? "PULL REQUEST" : "STOCK OUT"}{" "}
                  {details.rejected_by ? "(REJECTED)" : ""}
                  {details.approved_by ? "(POSTED)" : ""}
                </h2>
                {details.status_description && (
                  <Typography textAlign="center" variant="subtitle1">
                    {`(${details.status_description})`}
                  </Typography>
                )}
              </div>
            </div>

            <div
              style={{
                color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
              ID: {details.transactionid}
            </div>

            {details.is_stage_request ? (
              <>
                <div style={{}}>
                  Requested By By: {details.user_full_name} on{" "}
                  {momentPhoenix(details.created_on).format("MM/DD/YY")}
                </div>
                <div style={{}}>
                  Fulfilled By: {details.transferred_by_full_name} on{" "}
                  {momentPhoenix(details.created_on).format("MM/DD/YY")}
                </div>
              </>
            ) : (
              <div style={{}}>
                Stocked Out By: {details.transferred_by_full_name} on{" "}
                {momentPhoenix(details.created_on).format("MM/DD/YY")}
              </div>
            )}
            <div
              style={{
                color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                position: "relative",
                flexDirection: "row",
                display: isSmallScreen ? "column" : "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: isSmallScreen && !details.job_name ? "100%" : 50,
                }}
              >
                Job:{" "}
                {details.job_name
                  ? `${details.projectid} - ${details.job_name}`
                  : "[UNLISTED]"}
              </div>
            </div>

            {details.job_address && (
              <Link
                color={isPrinting ? "rgba(0,0,0,.5)" : "inherit"}
                target="_blank"
                rel="noopener noreferrer"
                href={`http://maps.google.com/?q=${encodeURIComponent(
                  details.job_address
                )}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: isSmallScreen && !details.job_name ? "100%" : 50,
                }}
              >
                Job Address: {details.job_address}
              </Link>
            )}

            {(details.instructions || details.notes) && (
              <div
                style={{
                  background: isPrinting ? "#d3d3d3" : "#363636",
                  padding: 8,
                  borderRadius: 4,
                }}
              >
                {details.instructions && (
                  <div
                    style={{ color: isPrinting ? "rgba(0,0,0,.8)" : "inherit" }}
                  >
                    Instructions: {details.instructions}
                  </div>
                )}
                {details.notes && (
                  <div
                    style={{ color: isPrinting ? "rgba(0,0,0,.8)" : "inherit" }}
                  >
                    Notes: {details.notes}
                  </div>
                )}
              </div>
            )}

            {details.request_mismatch_notes && (
              <div
                style={{
                  marginTop: 16,
                  borderStyle: "solid",
                  padding: 8,
                  borderRadius: 10,
                  // borderColor: "#FEFBE3",
                  // backgroundColor: "#FEFBE3",
                  background: isPrinting ? "#d3d3d3" : "#363636",
                }}
              >
                <h4
                  style={{ color: isPrinting ? "rgba(0,0,0,.5)" : "inherit" }}
                >
                  Quantity Mismatch Notes
                </h4>
                <div
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontStyle: "italic",
                  }}
                >
                  {details.request_mismatch_notes}
                </div>
              </div>
            )}

            {details.items.map((item, i) => (
              <div
                key={i}
                style={{
                  borderBottom: "2px solid black",
                  lineHeight: "2pt",
                  breakInside: "avoid",
                  breakAfter: "always",
                  marginLeft: "20px",
                  marginRight: "20px",
                  paddingTop: "10px",
                }}
              >
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontWeight: "600",
                  }}
                >
                  {item.item_description}
                </p>
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontSize: "15px",
                  }}
                >
                  SOURCE: {item.material_origin_key}
                </p>
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontSize: "15px",
                  }}
                >
                  REQUESTED: {item.requested_quantity}
                </p>
                <p
                  style={{
                    color: isPrinting ? "rgba(0,0,0,.5)" : "inherit",
                    fontSize: "15px",
                  }}
                >
                  QTY PULLED: {item.quantity}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <div>Loading...</div>
        </div>
      )}
    </PageContent>
  );
});

export default PrintPullDetails;
