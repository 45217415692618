import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
  Box,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import ImageModalButton from "../../../component/ImageModalButton";
import { GrabsContext } from "../../../context/GrabsContext";
import {
  Check,
  PanTool,
  ShoppingCart,
  ArrowCircleRight,
  ArrowCircleLeft,
} from "@mui/icons-material";
import PrintableQRCode from "../../../component/PrintableQRCode";
import inventoryIcon from "../../../assets/inventory.png";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import PageContent from "../../../component/PageContent";
import clickOnceWrapper from "../../../helpers/clickOnceWrapper";
import toFixedIfNecessary from "../../../helpers/toFixedIfNecessary";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useTheme } from "@emotion/react";
import momentPhoenix from "../../../helpers/momentPhoenix";

const InventoryItemDetails = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const { authToken, role } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { grabFromInventory } = useContext(GrabsContext);
  const [details, setDetails] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentQuantity, setCurrentQuantity] = useState(0);

  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const [startDate, setStartDate] = useState(
    momentPhoenix().startOf("year").format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(momentPhoenix().format("MM/DD/YYYY"));

  const selectStartDate = (val) => {
    setStartDate(val);
  };

  const selectEndDate = (val) => {
    setEndDate(val);
  };

  const getTransactions = async (controller) => {
    try {
      setLoading(true);

      let beginningOfStartDate = momentPhoenix(startDate)
        .startOf("day")
        .format("MM/DD/YYYY");
      let endOfEndDate = momentPhoenix(endDate)
        .endOf("day")
        .format("MM/DD/YYYY");

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await InventoryTracking.get(
        `/inventory/${id}/transactions?startDate=${beginningOfStartDate}&endDate=${endOfEndDate}`,
        data
      );
      console.log("Transactions", response.data);
      setTransactions(response.data.transactions);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getTransactions(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [startDate, endDate]);

  const getInventoryItemDetails = async () => {
    try {
      const response = await InventoryTracking.get(`/inventory/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setDetails(response.data.material);
      setCurrentQuantity(response.data.material.quantity);
    } catch (error) {
      console.log(error);
    }
  };

  const updateImage = async (imageBlob) => {
    try {
      let formData = new FormData();
      formData.append("internalCode", details.internal_code);
      if (imageBlob) formData.append("image", imageBlob);
      else formData.append("image", null);

      const response = await InventoryTracking.post(
        `/inventory/image`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "content-type": "multipart/form-data",
          },
        }
      );

      openSnackMessage("success", "Item Image Updated");
      await getInventoryItemDetails();
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const verifyCount = async () => {
    try {
      let data = { count: currentQuantity };
      const response = await InventoryTracking.put(`/inventory/${id}`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      openSnackMessage("success", "Item Quantity Updated");
      getInventoryItemDetails();
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  useEffect(() => {
    getInventoryItemDetails();
  }, []);

  return (
    <PageContent>
      {details ? (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: 16 }}>
              {!isSmallScreen && (
                <div
                  style={{
                    background: "white",
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <PrintableQRCode
                    url={`${window.location.host}/inventory/${id}`}
                    displaySize={150}
                    printSize={100}
                    topText={details.description}
                    subtext={details.internal_code}
                  />
                </div>
              )}
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{ width: 150, height: 50 }}
                  // style={{ width: 200, height: 70, fontSize: 24 }}
                  // disabled={imageModal.imageBlob === null}
                  variant="contained"
                  startIcon={<ShoppingCart />}
                  onClick={clickOnceWrapper(() => {
                    grabFromInventory(details);
                    navigate("/daily-stockouts/new");
                  })}
                >
                  Stock Out
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                {/* <div
                  style={{
                    position: "relative",
                    flexDirection: "row",
                    display: isSmallScreen ? "column" : "row",
                    marginTop: 16,
                    marginLeft: 16,
                    marginRight: 16,
                  }}
                >
                  <h2
                    style={{
                      textAlign: isSmallScreen ? "left" : "center",
                      flex: 1,
                      margin: 0,
                    }}
                  >
                    Inventory Item Details
                  </h2>
                </div> */}
                <h3 style={{ textAlign: "center" }}>{details.description}</h3>
                <div style={{ textAlign: "center" }}>
                  Code: {details.internal_code}
                </div>
                <div style={{ textAlign: "center" }}>
                  Quantity: {details.quantity}
                </div>
                <div style={{ textAlign: "center" }}>
                  Category: {details.category}
                </div>
                <div style={{ textAlign: "center" }}>
                  Branch: {details.branch}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ padding: 16 }}>
                  <ImageModalButton
                    currentImage={details.image_url}
                    onSubmit={(imageBlob) => {
                      updateImage(imageBlob);
                    }}
                  />
                  {role && role.toLowerCase() === "admin" ? (
                    <Grid
                      container
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 16,
                        marginBottom: 16,
                      }}
                    >
                      <Grid item md={7}>
                        <TextField
                          style={{ marginRight: 16 }}
                          id="time"
                          type="number"
                          label={`Current Inventory (${details.uom})`}
                          value={`${currentQuantity}`}
                          onChange={(e) => {
                            // setCurrentQuantity(e.target.value);
                            let val;
                            if (
                              e.target.value == "" ||
                              e.target.value === undefined ||
                              e.target.value === null ||
                              e.target.value === "0" ||
                              e.target.value === "00"
                            ) {
                              val = 0;
                            } else {
                              val = e.target.value.replace(/^0+/, "");
                              val = toFixedIfNecessary(val, 2);
                            }
                            // Do Not Allow Negative
                            // if (val < 0) {
                            //   val = 0;
                            // }
                            setCurrentQuantity(val);
                          }}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <Button
                          color="secondary"
                          style={{
                            color: "#fff",
                            width: isSmallScreen ? 100 : "100%",
                            height: 50,
                          }}
                          disabled={currentQuantity === details.quantity}
                          variant="contained"
                          onClick={() => {
                            verifyCount();
                          }}
                          startIcon={<Check />}
                        >
                          Adjust
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                position: "relative",
                flexDirection: "row",
                display: isSmallScreen ? "column" : "row",
                marginTop: 16,
                marginLeft: 16,
                marginRight: 16,
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  //background: "#d2d2d2",
                  flex: 1,
                  margin: 0,
                }}
              >
                Inventory Transactions
              </h2>
            </div>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    label="From"
                    inputFormat="MM/DD/YYYY"
                    value={startDate}
                    onChange={selectStartDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileDatePicker
                    label="To"
                    inputFormat="MM/DD/YYYY"
                    value={endDate}
                    onChange={selectEndDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </div>
            <TableContainer component={Box}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Job</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions &&
                    transactions.map((transaction, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {momentPhoenix(transaction.date_timestamp).format(
                            "MM/DD/YY h:mm a"
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            color: transaction.qty < 0 ? "red" : "green",
                          }}
                        >
                          {transaction.qty < 0 ? "" : "+"}
                          {transaction.qty}
                        </TableCell>
                        <TableCell>{transaction.reference}</TableCell>
                        <TableCell>{transaction.job_name}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        <div style={{ height: "100%" }}>
          <div>Loading...</div>
        </div>
      )}
    </PageContent>
  );
};

export default InventoryItemDetails;
